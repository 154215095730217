import DOMPurify from 'dompurify'
import { marked } from 'marked'

const acceptableNodeTargets = ['_blank', '_self', '_parent', '_top']

const sanitizer = DOMPurify()

sanitizer.addHook('afterSanitizeAttributes', function(node) {
  if('target' in node) {
    const nodeTarget = acceptableNodeTargets.includes(node.getAttribute('target') ?? '') ? node.getAttribute('target')! : '_blank'
    node.setAttribute('target', nodeTarget)
    node.setAttribute('rel', 'noopener')
  }
})

export function renderToHtml(markdown: string): string {
  return sanitizer.sanitize(marked.parse(markdown))
}