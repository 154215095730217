export interface PodcastEpisode {
  title: string
  date: string
  description: string
  duration: string
  link: string
}

// rss feed: https://feeds.soundcloud.com/users/soundcloud:users:802030363/sounds.rss

export const podcastEpisodes: PodcastEpisode[] = [
  {
    title: 'Whistling Straits Pilgrimage',
    date: 'Fri, 21 Oct 2022 21:02:49 +0000',
    description: 'Nic and Clay are back to discuss their round at Whistling Straits which capped off a long golf road trip around Lake Michigan.',
    duration: '00:51:31',
    link: 'https://soundcloud.com/the-downswing-golf/whistling-straits-pilgrimage'
  },
  {
    title: 'Major Cam',
    date: 'Sat, 23 Jul 2022 05:09:42 +0000',
    description: 'Nic and Clay recap Cameron Smith\'s first major championship at The Open and preview the upcoming Mark Zelich Classic.',
    duration: '00:48:49',
    link: 'https://soundcloud.com/the-downswing-golf/major-cam'
  },
  {
    title: 'Hacking The Lido with Brian Zager',
    date: 'Sun, 01 May 2022 02:10:37 +0000',
    description: 'Nic and Clay welcome Brian Zager to the podcast to share how he got the chance to use technology in the recreation of The Lido at the Sand Valley Golf Resort.',
    duration: '00:53:42',
    link: 'https://soundcloud.com/the-downswing-golf/hacking-the-lido-with-brian-zager'
  }
]