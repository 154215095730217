
import { computed, defineComponent } from 'vue'
import { podcastEpisodes } from '@/assets/data/podcast'
import { formatDate } from '@/utils/text'

export default defineComponent({
  name: 'PodcastEpisodes',
  props: {
    count: {
      type: Number,
      default: null
    },
    showThumbnail: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const filteredEpisodes = computed(() => {
      if(!props.count) return podcastEpisodes
      return podcastEpisodes.slice(0, props.count)
    })

    function formatDuration(duration: string): string {
      let d = duration.split(':');
      if( parseInt(d[0]) > 0 ) {
        return `${parseInt(d[0])}h ${parseInt(d[1])}m`;
      } else {
        return `${parseInt(d[1])}m`;
      }
    }

    function trimDescription(description: string): string {
      return `${description.split('.')[0]}.`;
    }

    return {
      filteredEpisodes,
      formatDate,
      formatDuration,
      trimDescription
    }
  }
})
