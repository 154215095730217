import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "post__header"
}
const _hoisted_3 = { class: "text-uppercase" }
const _hoisted_4 = { class: "blog-posts row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hero = _resolveComponent("Hero")!
  const _component_MediaLinkPreview = _resolveComponent("MediaLinkPreview")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["blog page-container transition__page", { 'isShowing': _ctx.state.isShowing }])
  }, [
    _createVNode(_component_Hero, {
      headline: _ctx.decodeUrlFriendlyTitle(_ctx.state.filters.author),
      headlineImage: _ctx.authorImage,
      imageSrc: "blog-author-hero.jpg",
      imageAlt: "Blog author hero image"
    }, null, 8, ["headline", "headlineImage"]),
    _createElementVNode("section", _hoisted_1, [
      (_ctx.state.filters.author.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, "All Posts by " + _toDisplayString(_ctx.decodeUrlFriendlyTitle(_ctx.state.filters.author)), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blogPosts, (bp, bpIndex) => {
          return (_openBlock(), _createBlock(_component_MediaLinkPreview, {
            key: bpIndex,
            cta: "Click To Read",
            id: bp.date,
            image: bp.image,
            media: "blog",
            size: "half",
            title: bp.title
          }, null, 8, ["id", "image", "title"]))
        }), 128))
      ])
    ])
  ], 2))
}