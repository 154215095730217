
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HeroSection',
  props: {
    headline: {
      type: String,
      default: ''
    },
    headlineImage: {
      type: String,
      default: null
    },
    imageSrc: {
      type: String,
      required: true
    },
    imageAlt: {
      type: String,
      required: true
    }
  }
})
