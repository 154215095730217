export const instructionData = [
  {
    title: 'The Arm Swing Illusion',
    author: 'Jim Waldron Balance Point Golf',
    tourPro: false,
    area: '',
    youtubeId: 'ASH06DwHaRw'
  },
  {
    title: 'How To Fix Golf Slice With Driver',
    author: 'Scratch Golf Academy',
    tourPro: false,
    area: 'driver',
    youtubeId: 'f5zTSdWfkmo'
  },
  {
    title: 'How To Shape Your Iron Shots',
    author: 'Callaway Golf',
    tourPro: true,
    area: 'irons',
    youtubeId: 'SKcD7IjbhE8'
  },
  {
    title: 'Chipping 101',
    author: 'Callaway Golf',
    tourPro: true,
    area: 'chipping',
    youtubeId: 'IvmHU5Hcs2k'
  },
  {
    title: 'One Tip That Will Change Your Chipping Forever',
    author: 'Meandmygolf',
    tourPro: false,
    area: 'chipping',
    youtubeId: 'n-G44RSHHzg'
  },
  {
    title: 'Dead Hand Wedge with Jason Day',
    author: 'TaylorMade Golf',
    tourPro: false,
    area: 'chipping',
    youtubeId: 'CyWEhCyyaYY'
  }
];