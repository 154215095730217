
import { defineComponent, onMounted, reactive } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import { delay } from '@/utils/async'
import { useMeta } from 'vue-meta'

import Hero from '@/components/Hero.vue'
import PodcastEpisodes from '@/components/PodcastEpisodes.vue'
import { PodcastEpisode } from '@/assets/data/podcast'

interface State {
  episodes: PodcastEpisode[]
  isShowing: boolean
  months: string[]
  subPage: string
  pages: string[]
}

export default defineComponent({
  name: 'DownswingPodcast',
  components: {
    Hero,
    PodcastEpisodes
  },
  setup() {
    const state = reactive<State>({
      episodes: [],
      isShowing: false,
      months: ['January','February','March','April','May','June','July','August','September','October','November','December'],
      subPage: 'about',
      pages: ['episodes', 'about']
    })

    function getEpisodes() {
      // let podcast = this;
      // $.ajax({
      //   url: '/api/podcast/',
      //   success: function(data) {
      //     $(data).find('item').each(function () {
      //       let el = $(this);
      //       podcast.episodes.push(
      //         {
      //           title: el.find('title').text(),
      //           date: el.find('pubDate').text(),
      //           description: el.find('description').text(),
      //           duration: el.children()[4].innerHTML,
      //           link: el.find('link').text()
      //         }
      //       );
      //     });
      //     podcast.saveEpisodes();
      //   },
      //   error: function(e) {
      //     console.log(e);
      //   }
      // });
    }

    function loadEpisodes() {
      const now = new Date().getTime();
      if( supports_html5_storage() ) {
        if(localStorage.getItem('fc_pod_ep') != null && localStorage.getItem('fc_pod_ep_date') != null) {
          const prev = parseInt( JSON.parse( localStorage.getItem('fc_pod_ep_date') ?? '' ) );
          if( now - prev < 86400000) {  // one day
            state.episodes = JSON.parse( localStorage.getItem('fc_pod_ep') ?? '' );
          } else {
            getEpisodes();
          }
        } else {
          getEpisodes();
        }
      } else {
        getEpisodes();
      }
    }

    function saveEpisodes() {
      if( supports_html5_storage() ) {
        localStorage.setItem( 'fc_pod_ep_date', new Date().getTime().toString() );
        localStorage.setItem( 'fc_pod_ep', JSON.stringify(state.episodes) );
      } else {
        console.log('Error: Local storage not available.')
      }
    }

    function supports_html5_storage() {
      try {
        return 'localStorage' in window && window['localStorage'] !== null;
      } catch (e) {
        return false;
      }
    }
    
    function switchPage(page: string) {
      state.subPage = page;
    }

    onBeforeRouteLeave(async () => {
      state.isShowing = false
      await delay(800)
    })

    onMounted(async () => {      
      useMeta({ title: 'The Downswing - Golf Podcast' })
      loadEpisodes()
      await delay(200)
      state.isShowing = true
    })

    return {
      state,
      saveEpisodes,
      switchPage,
    }
  }
})
