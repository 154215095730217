export interface BlogPost {
  title: string
  author: string
  date: string
  tags: string[]
  image: string
  markdown: string
}

export const blogData: BlogPost[] = [
  // {
  //   title: 'Testing 123',
  //   author: 'Test Man',
  //   date: '2022-12-18',
  //   tags: ['test'],
  //   image: 'blog/dove-valley-ranch-hero.jpg',
  //   markdown: '20220930-fall-golf.md'
  // },
  {
    title: '2023 April Players of the Month',
    author: 'ForeChamp',
    date: '2023-05-01',
    tags: ['players of the month', 'awards', 'professional golf'],
    image: 'blog/hero/awards-hero.jpg',
    markdown: '20230501-april-players-of-the-month.md'
  },
  {
    title: '2023 March Players of the Month',
    author: 'ForeChamp',
    date: '2023-04-03',
    tags: ['players of the month', 'awards', 'professional golf'],
    image: 'blog/hero/awards-hero.jpg',
    markdown: '20230403-march-players-of-the-month.md'
  },
  {
    title: 'Augusta National To Open For Public Tee Times',
    author: 'ForeChamp',
    date: '2023-04-01',
    tags: ['general', 'golf course'],
    image: 'blog/hero/augusta-national-hero.jpg',
    markdown: '20230401-augusta-national-to-open-for-public-tee-times.md'
  },
  {
    title: 'Bittersweet Spring',
    author: 'Clay Schlegel',
    date: '2023-03-20',
    tags: ['general'],
    image: 'blog/hero/birdie-challenge-hero.jpg',
    markdown: '20230320-bittersweet-spring.md'
  },
  {
    title: 'Is The Players Still The Fifth Major?',
    author: 'Clay Schlegel',
    date: '2023-03-13',
    tags: ['professional golf', 'pga tour'],
    image: 'blog/hero/2k23-tpc-sawgrass-17.jpg',
    markdown: '20230313-is-the-players-still-the-fifth-major.md'
  },
  {
    title: 'Can PGA Tour and LIV Golf Coexist?',
    author: 'Clay Schlegel',
    date: '2023-03-06',
    tags: ['professional golf', 'pga tour', 'liv golf'],
    image: 'blog/hero/rory-vs-greg-hero.jpg',
    markdown: '20230306-can-pga-tour-and-liv-golf-coexist.md'
  },
  {
    title: '2023 February Players of the Month',
    author: 'ForeChamp',
    date: '2023-03-01',
    tags: ['players of the month', 'awards', 'professional golf'],
    image: 'blog/hero/awards-hero.jpg',
    markdown: '20230301-february-players-of-the-month.md'
  },
  {
    title: 'Netflix Full Swing Season 1 Review',
    author: 'Clay Schlegel',
    date: '2023-02-27',
    tags: ['review', 'professional golf', 'pga tour', 'liv golf'],
    image: 'blog/hero/full-swing-hero.jpg',
    markdown: '20230227-netflix-full-swing-season-1-review.md'
  },
  {
    title: '2023 January Players of the Month',
    author: 'ForeChamp',
    date: '2023-02-01',
    tags: ['players of the month', 'awards', 'professional golf'],
    image: 'blog/hero/awards-hero.jpg',
    markdown: '20230201-january-players-of-the-month.md'
  },
  {
    title: 'Cold Weather Tips',
    author: 'Clay Schlegel',
    date: '2022-11-01',
    tags: ['tips'],
    image: 'blog/hero/cold-weather-hero.jpg',
    markdown: '20221101-cold-weather-tips.md'
  },
  {
    title: 'Top 5 Alister MacKenzie Courses',
    author: 'ForeChamp',
    date: '2022-10-10',
    tags: ['top 5', 'architecture', 'golf course'],
    image: 'blog/hero/augusta-national-hero.jpg',
    markdown: '20221010-top-5-alister-mackenzie.md'
  },
  {
    title: 'My Favorite Time of Year',
    author: 'Clay Schlegel',
    date: '2022-09-30',
    tags: ['general'],
    image: 'blog/hero/fall-golf-hero.jpg',
    markdown: '20220930-fall-golf.md'
  },
  {
    title: 'First Eagle!',
    author: 'Clay Schlegel',
    date: '2021-11-11',
    tags: ['achievement'],
    image: 'blog/hero/dove-valley-ranch-hero.jpg',
    markdown: '20211111-first-eagle.md'
  },
  {
    title: 'Birdie Challenge Update - July 2021',
    author: 'Clay Schlegel',
    date: '2021-07-30',
    tags: ['birdie challenge'],
    image: 'blog/hero/birdie-challenge-hero.jpg',
    markdown: '20210730-birdie-challenge-update.md'
  },
  {
    title: 'Birdie Challenge Update - April 2021',
    author: 'Clay Schlegel',
    date: '2021-04-30',
    tags: ['birdie challenge'],
    image: 'blog/hero/birdie-challenge-hero.jpg',
    markdown: '20210430-birdie-challenge-update.md'
  },
  {
    title: 'Give Your Game A Reboot',
    author: 'Clay Schlegel',
    date: '2021-04-27',
    tags: ['tips'],
    image: 'blog/hero/reboot-hero.jpg',
    markdown: '20210427-reboot-game.md'
  }
];