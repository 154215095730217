import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/HomeView.vue'
import Blog from '@/views/BlogView.vue'
import BlogAuthor from '@/views/BlogAuthor.vue'
import BlogCategory from '@/views/BlogCategory.vue'
import BlogPost from '@/views/BlogPost.vue'
import Podcast from '@/views/Podcast.vue'
// import TanzaniteCountryClub from '@/views/TanzaniteCountryClub.vue'
import NotFound from '@/views/404.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'ForeChamp | Home'
    }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog,
  },
  {
    path: '/blog/author/:name',
    name: 'BlogAuthor',
    component: BlogAuthor
  },
  {
    path: '/blog/category/:tag',
    name: 'BlogCategory',
    component: BlogCategory
  },
  {
    path: '/blog/post/:id/:title',
    name: 'BlogPost',
    component: BlogPost,
  },
  {
    path: '/podcast',
    name: 'Podcast',
    component: Podcast,
    meta: {
      title: 'The Downswing - Golf Podcast'
    }
  },
  // {
  //   path: '/tanzanitecc',
  //   name: 'TanzaniteCountryClub',
  //   component: TanzaniteCountryClub
  // },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(() => {
  window.scrollTo(0, 0)
})

export default router
