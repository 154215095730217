
import { computed, defineComponent, onMounted, reactive } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { delay } from '@/utils/async'
import { decodeUrlFriendlyTitle, urlFriendlyTitle } from '@/utils/text'
import { blogData, BlogPost } from '@/assets/data/blog'
import { useMeta } from 'vue-meta'
import { now } from '@/utils/time'

import Hero from '@/components/Hero.vue'
import MediaLinkPreview from '@/components/MediaLinkPreview.vue'

interface State {
  isShowing: boolean
  filters: {
    tag: string
  }
}

export default defineComponent({
  name: 'BlogCategory',
  components: {
    Hero,
    MediaLinkPreview
  },
  setup() {
    const router = useRouter()

    const state = reactive<State>({
      isShowing: false,
      filters: {
        tag: ''
      }
    })

    const blogPosts = computed<BlogPost[]>(() => blogData.filter(x => isFilterMatch(x) && new Date(x.date).getTime() < now.getTime()))

    function isFilterMatch(post: BlogPost): boolean {
      return post.tags.indexOf( decodeUrlFriendlyTitle(state.filters.tag) ) >= 0
    }

    onBeforeRouteLeave(async () => {
      state.isShowing = false
      await delay(800)
    })

    onMounted(async () => {
      state.filters.tag = router.currentRoute.value.params.tag as string ?? ''
      useMeta({ title: 'Blog' })
      await delay(200)
      state.isShowing = true
    })

    return {
      state,
      blogPosts,
      decodeUrlFriendlyTitle,
      urlFriendlyTitle
    }
  }
})
