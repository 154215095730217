
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'GlobalFooter',
  setup() {
    const now = new Date()
    const currentYear = computed(() => now.getFullYear())

    return {
      currentYear
    }
  }
})
