
import { computed, defineComponent } from 'vue'
import { urlFriendlyTitle } from '@/utils/text'

export default defineComponent({
  name: 'MediaLinkPreview',
  props: {
    cta: {
      type: String,
      default: 'Click To View'
    },
    id: {
      type: String,
      default: ''
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: ''
    },
    media: {
      type: String,
      default: 'youtube'
    },
    size: {
      type: String,
      default: 'full'
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const link = computed(() => {
      if(props.media === 'blog') return `/blog/post/${props.id}/${urlFriendlyTitle(props.title)}`
      if(props.media === 'podcast') return '/podcast'
      return `https://www.youtube.com/watch?v=${props.id}`
    })

    const backgroundImage = computed(() => {
      if(props.media === 'youtube') return `url(https://img.youtube.com/vi/${props.id}/hqdefault.jpg)`
      return `url(${require(`@/assets/${props.image}`)})`
    })

    return {
      backgroundImage,
      link
    }
  }
})
