
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  name: 'NotFound',
  setup() {
    onMounted(() => {
      document.title = 'ForeChamp | FORE OH FORE!'
    })
  }
})
