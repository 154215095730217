export const months = ['January','February','March','April','May','June','July','August','September','October','November','December']

export function decodeUrlFriendlyTitle(title: string): string {
  return title.split('-').join(' ')
}

export function formatDate(date: string, hasTime: boolean = false): string {
  const d = new Date(`${date.split(' +')[0]}${!hasTime ? 'T00:00:00-06:00' : ''}`)
  return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`
}

export function urlFriendlyTitle(title: string): string {
  return title.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-')
}