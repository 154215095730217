import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba0a97f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href", "title"]
const _hoisted_2 = { class: "transition" }
const _hoisted_3 = { class: "transition" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.media === 'youtube')
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.link,
        target: "_blank",
        title: `Watch ${_ctx.title}`,
        class: _normalizeClass(["media-link__preview", [ _ctx.size ]])
      }, [
        _createElementVNode("div", {
          class: "media",
          style: _normalizeStyle({ 'background-image': _ctx.backgroundImage })
        }, null, 4),
        _createElementVNode("div", {
          class: _normalizeClass(["title transition", { 'show-on-hover': _ctx.hideTitle }])
        }, [
          _createTextVNode(_toDisplayString(_ctx.title), 1),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.cta), 1)
        ], 2)
      ], 10, _hoisted_1))
    : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        to: _ctx.link,
        class: _normalizeClass(["media-link__preview", [ _ctx.size ]])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "media",
            style: _normalizeStyle({ 'background-image': _ctx.backgroundImage })
          }, null, 4),
          _createElementVNode("div", {
            class: _normalizeClass(["title transition", { 'show-on-hover': _ctx.hideTitle }])
          }, [
            _createTextVNode(_toDisplayString(_ctx.title), 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.cta), 1)
          ], 2)
        ]),
        _: 1
      }, 8, ["to", "class"]))
}