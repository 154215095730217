import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "hero" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "hero__background",
      src: require(`@/assets/${_ctx.imageSrc}`),
      alt: _ctx.imageAlt
    }, null, 8, _hoisted_2),
    (_ctx.headline !== '')
      ? (_openBlock(), _createElementBlock("h1", _hoisted_3, [
          (_ctx.headlineImage)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: require(`@/assets/${_ctx.headlineImage}`),
                alt: "Author profile image"
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true),
          _createElementVNode("span", null, _toDisplayString(_ctx.headline), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}