
import { defineComponent, reactive } from 'vue'

interface State {
  showMobileNav: boolean
}

export default defineComponent({
  name: 'GlobalHeader',
  setup() {
    const state = reactive<State>({
      showMobileNav: false
    })

    function closeNav() {
      state.showMobileNav = false;
    }

    function toggleNav() {
      state.showMobileNav = !state.showMobileNav;
    }

    return {
      state,
      closeNav,
      toggleNav
    }
  }
})
