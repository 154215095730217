import { createApp } from 'vue'
import { createMetaManager, useMeta } from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(createMetaManager())
  .mount('#app')